export const STATUSES = [
  { label: "Open", value: "OPEN" },
  { label: "In Progress", value: "IN_PROGRESS" },
  { label: "Closed", value: "CLOSED" },
  { label: "Ready For Approval", value: "VALIDATE" },
];
export const SORT_OPTION = [
  { label: "Identifier", value: "identifier" },
  { label: "Plant", value: "plant" },
  { label: "Added At", value: "addedAt" },
  { label: "Investigated At", value: "investigatedAt" },
  { label: "Repaired At", value: "repairedAt" },
  { label: "Status", value: "status" },
  { label: "Failure Reason", value: "failureReason" },
  { label: "Monitoring Comments", value: "monitoringComments" },
  { label: "Works Comments", value: "workComments" },
  { label: "No.of Strings fixed per duct", value: "noOfStringsPerDuct" },
];

export const ORDER_OPTION = [
  { label: "Ascending", value: "ASC" },
  { label: "Descending", value: "DESC" },
];

export const STRING_FILTER = "@/bge/string_filters";

export const MONTH = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const statusColorTheme = {
  OPEN: { backgroundColor: "#ff0000", color: "#ffffff", borderColor: "#ff0000" },
  IN_PROGRESS: { backgroundColor: "#008000", color: "#ffffff", borderColor: "#008000" },
  READY_FOR_APPROVAL: { backgroundColor: "#ff1493", color: "#ffffff", borderColor: "#ff1493" },
  COMPLETED: { backgroundColor: "#0000ff", color: "#ffffff", borderColor: "#0000ff" },
};
