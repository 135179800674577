export const CORRECTIVE_TASK_FIELDS = [
    {
        label: "Title",
        value: "title",
        field_value: "title"
    },

    {
        label: "Priority",
        value: "priority",
        field_key: "priority"
    },
    {
        label: "Identifier or Acronym",
        value: "identifier",
        field_key: "identifier"
    },

    {
        label: "Started At",
        value: "startedAt",
        field_key: "startedAt"
    },
    {
        label: "Resolved At",
        value: "resolvedAt",
        field_key: "resolvedAt"
    },
    {
        label: "Status",
        value: "status",
        field_key: "status"
    },
    {
        label: "Category",
        value: "category",
        field_key: "category"
    },
    {
        label: "Asset Category",
        value: "assetCategory.name",
        field_key: "assetCategory"
    },
    {
        label: "Description",
        value: "description",
        field_key: "description"
    },
    {
        label: "Labour Hours",
        value: "labourHours",
        field_key: "labourHours"
    },
    {
        label: "Created By",
        value: "createdById",
        field_key: "createdBy"
    },
    {
        label: "Parent",
        value: "parentId",
        field_key: "parentId"
    },
    {
        label: "Comment",
        value: "comment",
        field_key: "comment"
    },
    {
        label: "Is Archived",
        value: "isArchived",
        field_key: "isArchived"
    },
    {
        value: "plant.name",
        label: "Plant Name",
        field_key: "plants"
    },
    {
        value: "sla.name",
        label: "SLA",
        field_key: "sla"
    },
    {
        value: "assignedTo",
        label: "Assigned Engineers",
        field_key: "assignedToIds"
    },
    {
        value: "fieldEngineers",
        label: "Field Engineers",
        field_key: "fieldEngineerIds"
    },
    {
        value: "attachments",
        label: "Attachments",
        field_key: "attachments"
    },
    {
        label: "Resolved Time (hrs)",
        value: "resolutionTime",
        field_key: "resolutionTime"
    },
];

export const REMEDIAL_TASK_FIELDS = [
    {
        label: "Title",
        value: "title",
        field_value: "title"
    },

    {
        label: "Priority",
        value: "priority",
        field_key: "priority"
    },
    {
        label: "Identifier or Acronym",
        value: "identifier",
        field_key: "identifier"
    },

    {
        label: "Started At",
        value: "startedAt",
        field_key: "startedAt"
    },
    {
        label: "Resolved At",
        value: "resolvedAt",
        field_key: "resolvedAt"
    },
    {
        label: "Status",
        value: "status",
        field_key: "status"
    },
    {
        label: "Category",
        value: "category",
        field_key: "category"
    },
    {
        label: "Asset Category",
        value: "assetCategory.name",
        field_key: "assetCategory"
    },
    {
        label: "Description",
        value: "description",
        field_key: "description"
    },
    {
        label: "Created By",
        value: "createdById",
        field_key: "createdBy"
    },
    {
        label: "Parent",
        value: "parentId",
        field_key: "parentId"
    },
    {
        label: "Comment",
        value: "comment",
        field_key: "comment"
    },
    {
        label: "Is Archived",
        value: "isArchived",
        field_key: "isArchived"
    },
    {
        value: "plant.name",
        label: "Plant Name",
        field_key: "plants"
    },
    {
        value: "assignedTo",
        label: "Assigned Engineers",
        field_key: "assignedToIds"
    },
    {
        value: "fieldEngineers",
        label: "Field Engineers",
        field_key: "fieldEngineerIds"
    },
    {
        value: "attachments",
        label: "Attachments",
        field_key: "attachments"
    },
];

export const PREVENTATIVE_TASK_FIELDS = [
    {
        label: "Title",
        value: "title",
        field_value: "title"
    },

    {
        label: "Priority",
        value: "priority",
        field_key: "priority"
    },
    {
        label: "Identifier or Acronym",
        value: "identifier",
        field_key: "identifier"
    },

    {
        label: "Started At",
        value: "startedAt",
        field_key: "startedAt"
    },
    {
        label: "Resolved At",
        value: "resolvedAt",
        field_key: "resolvedAt"
    },
    {
        label: "Status",
        value: "status",
        field_key: "status"
    },
    {
        label: "Category",
        value: "category",
        field_key: "category"
    },
    {
        label: "Asset Category",
        value: "assetCategory.name",
        field_key: "assetCategory"
    },
    {
        label: "Description",
        value: "description",
        field_key: "description"
    },
    {
        label: "Year",
        value: "year",
        field_key: "year"
    },
    {
        label: "Frequency",
        value: "frequency",
        field_key: "frequency"
    },
    {
        label: "Code",
        value: "code",
        field_key: "code"
    },
    {
        label: "month",
        value: "months",
        field_key: "months"
    },
    {
        label: "Created By",
        value: "createdById",
        field_key: "createdBy"
    },
    {
        label: "Parent",
        value: "parentId",
        field_key: "parentId"
    },
    {
        label: "Comment",
        value: "comment",
        field_key: "comment"
    },
    {
        label: "Is Archived",
        value: "isArchived",
        field_key: "isArchived"
    },
    {
        value: "plant.name",
        label: "Plant Name",
        field_key: "plants"
    },
    {
        value: "assignedTo",
        label: "Assigned Engineers",
        field_key: "assignedToIds"
    },
    {
        value: "fieldEngineers",
        label: "Field Engineers",
        field_key: "fieldEngineerIds"
    },
    {
        value: "attachments",
        label: "Attachments",
        field_key: "attachments"
    },
];