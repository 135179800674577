import React, { useCallback, useEffect } from "react";
import { useReactTable, flexRender, getCoreRowModel, getSortedRowModel } from "@tanstack/react-table";
import "./style.css";
import { DataTableBody, Icon } from "../Component";
import classNames from "classnames";
import { useDebounce } from "../../hooks/useDebounce";

function TanstackTable({ dataSource, _column, handleSorting, onColumWidthChanged, columnSettings, columnMinWidth }) {
  const [sorting, setSorting] = React.useState([]);
  const [initialColumnSizes, setInitialColumnSizes] = React.useState({});

  const initialColumnSettings = React.useMemo(() => {
    return (
      (columnSettings?.fields?.length &&
        columnSettings?.fields
          .sort((a, b) => a.position - b.position)
          .reduce((acc, field) => {
            acc[field.field_key] = field.width;
            return acc;
          }, {})) ||
      {}
    );
  }, [columnSettings]);

  const callResizeAPI = useCallback(
    (resizedValue) => {
      onColumWidthChanged({
        field_key: Object.keys(resizedValue)[0],
        width: Object.values(resizedValue)[0],
      });
    },
    [initialColumnSizes]
  );

  const [debouncedCallResizeAPI, cancelDebounce] = useDebounce(callResizeAPI, 200);

  useEffect(() => {
    if (initialColumnSettings) {
      setInitialColumnSizes(initialColumnSettings);
    }
  }, [initialColumnSettings]);

  useEffect(() => {
    if (Object.keys(columnSettings).length && Object.keys(columnSettings?.filters).length) {
      setSorting([{ id: columnSettings.filters?.sortingField, desc: columnSettings.filters?.sortingOrder === "DESC" }]);
    }
  }, [columnSettings]);

  const handleSortChange = (item) => {
    let newColumnSorting = [];
    if (typeof item === "function") {
      const sortValue = item();
      const sortBy = sortValue[0].id;
      const currentSorting = sorting.find((s) => s.id === sortBy);

      let sortDirection;
      if (currentSorting) {
        if (currentSorting.desc === false) {
          sortDirection = "DESC";
        } else if (currentSorting.desc === true) {
          sortDirection = "ASC";
        } else {
          sortDirection = "DESC";
        }
      } else {
        sortDirection = "ASC";
      }
      newColumnSorting = sortDirection ? [{ id: sortBy, desc: sortDirection === "DESC" }] : [];
      handleSorting({ sortingField: sortBy, sortingOrder: sortDirection });
      setSorting(newColumnSorting);
    }
  };

  function handleColumnSizing(columnSizing) {
    const resizedValue = columnSizing();
    setInitialColumnSizes((prev) => ({ ...prev, ...resizedValue }));
    debouncedCallResizeAPI(resizedValue);
  }

  const tableInstance = useReactTable({
    data: dataSource,
    columns: _column,
    enableColumnResizing: true,
    enableSorting: true,
    manualSorting: true,
    state: {
      sorting,
      columnSizing: initialColumnSizes,
    },
    columnResizeMode: "onChange",
    onSortingChange: handleSortChange,
    onColumnSizingChange: handleColumnSizing,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const rowClass = classNames({
    "nk-tb-col": true,
    [`tb-col-md`]: true,
  });
  

  return (
    <>
      <DataTableBody className={"table-body"} compact>
        <table
          style={{
            width: tableInstance.getCenterTotalSize(),
          }}
        >
          <thead className="nk-tb-head">
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr className="table-head-row"  key={`tr-${headerGroup.id}`}>
                {headerGroup.headers.map((header) => {
                  const canSort = header.column.getCanSort();
                  const isSorted = header.column.getIsSorted();
                  return (
                    <th
                      key={`th-${header.id}`}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize()
                          , cursor: "pointer" }}
                      cursor={canSort ? "pointer" : "inherit"}
                      className="nk-tb-col"
                    >
                      <div style={{ display: "flex" }}>
                        <div onClick={header.column.getToggleSortingHandler()} className="sub-text pr-1">
                          <div className="tableData">
                            <p
                              style={{
                                width: header.column.getSize(),
                              }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </p>
                          </div>
                        </div>
                        {header.column.getCanResize() ? (
                          <div
                            {...{
                              onDoubleClick: () => header.column.resetSize(),
                              onMouseDown: header.getResizeHandler(),
                              onTouchStart: header.getResizeHandler(),
                              className: `resizer ${tableInstance.options.columnResizeDirection} ${header.column.getIsResizing() ? "isResizing" : ""
                              }`,
                              style: {
                                // transform: header.column.getIsResizing()
                                //   ? `translateX(${
                                //       (tableInstance.options.columnResizeDirection === "rtl" ? -1 : 1) *
                                //       (tableInstance.getState().columnSizingInfo.deltaOffset ?? 0)
                                //     }px)`
                                //   : "",
                              },
                            }}
                          ></div>
                        ): <div
                        style={{cursor:"not-allowed"}}
                        {...{
                          className: `resizer ${tableInstance.options.columnResizeDirection} ${"isResizing"}`}}
                      ></div>}
                        {canSort ? (
                          !isSorted ? (
                            <div className="d-flex flex-column justify-content-center">
                              <Icon
                                onClick={header.column.getToggleSortingHandler()}
                                style={{ margin: "0px 0px -3px 0px" }}
                                name={"caret-up"}
                              ></Icon>
                              <Icon onClick={header.column.getToggleSortingHandler()} name={"caret-down"}></Icon>
                            </div>
                          ) : isSorted === "asc" ? (
                            <div className="d-flex flex-column justify-content-center">
                              <Icon
                                onClick={header.column.getToggleSortingHandler()}
                                style={{ margin: "0px 0px -3px 0px" }}
                                name={"caret-up-fill "}
                              ></Icon>
                              <Icon onClick={header.column.getToggleSortingHandler()} name={"caret-down"}></Icon>
                            </div>
                          ) : (
                            <div className="d-flex flex-column justify-content-center">
                              <Icon
                                style={{ margin: "0px 0px -3px 0px" }}
                                onClick={header.column.getToggleSortingHandler()}
                                name={"caret-up"}
                              ></Icon>
                              <Icon onClick={header.column.getToggleSortingHandler()} name={"caret-down-fill"}></Icon>
                            </div>
                          )
                        ) : null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody>
            {tableInstance.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className="nk-tb-item">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className={rowClass}
                      {...{
                        key: cell.id,
                        style: {
                          width: cell.column.getSize(),
                        },
                      }}
                      key={cell.id}
                    >
                      <div className="tableData" >
                        <p
                          style={{
                            width: cell.column.getSize()
                          }}
                        >
                          {" "}
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </p>
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </DataTableBody>
    </>
  );
}

export default TanstackTable;
