import { format } from "date-fns";
import React, { useContext } from "react";
import { Col, FormGroup, Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  Icon,
  Button,
  BlockTitle,
} from "../../../components/Component";
import { TASK } from "../../equipment_spares/constants";
import { TicketManagementContext } from "../TicketManagementProvider";
import { MONTH } from "../strings/components/constants";
import Attachments from "../../../components/attachments/Attachment";
import { convertToHoursAndMinutes } from "../../../utils/DateUtil";

const ViewTicket = ({ sm, updateSm }) => {
  const ticketManagementContext = useContext(TicketManagementContext);
  const { currentViewTicket } = ticketManagementContext;

  if (!currentViewTicket) return <Spinner />;

  const category = (categoryName) => {
    switch (categoryName) {
      case "corrective":
        return "Corrective Maintenance";
      case "preventative":
        return "Preventative Maintenance";
      case "remedial":
        return "Remedial Maintenance";
      default:
        return "N/A";
    }
  };
  return (
    <>
      <Block className={"bg-white p-4"}>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">{category(currentViewTicket.category)}</BlockTitle>
              <BlockDes>
                <p>Basic info.</p>
              </BlockDes>
            </BlockHeadContent>

          </BlockBetween>
        </BlockHead>
        <Block size="lg">
          <div className="profile-ud-list">
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Ticket Name</span>
                <span className="profile-ud-value">{currentViewTicket.title || "NA"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Status</span>
                <span className="profile-ud-value">{currentViewTicket.status || "-"}</span>
              </div>
            </div>
            {currentViewTicket.category === "preventative" &&
              (
                <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Code</span>
                    <span className="profile-ud-value">{currentViewTicket.code || "-"} </span>
                  </div>
                </div>

              )
            }
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Plant Name</span>
                <span className="profile-ud-value">{currentViewTicket.plant.name || "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Start Date</span>
                <span className="profile-ud-value">
                  {" "}
                  {currentViewTicket?.startedAt
                    ? format(new Date(currentViewTicket?.startedAt || null), "dd MMM yyyy")
                    : "-"}</span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Resolved Date</span>
                <span className="profile-ud-value">
                  {" "}
                  {currentViewTicket?.resolvedAt
                    ? format(new Date(currentViewTicket?.resolvedAt || null), "dd/MM/yyyy")
                    : "-"} </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Assigned Engineer</span>
                <span className="profile-ud-value">
                  {currentViewTicket?.assignedEngineer
                    ? currentViewTicket?.assignedEngineer
                      ?.map((item, key) => {
                        return `${item.firstName} ${item.lastName}`;
                      })
                      .join(", ")
                    : "-"}
                </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Description</span>
                <span className="profile-ud-value">
                  {currentViewTicket.description || "-"} </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="profile-ud-label">Comments</span>
                <span className="profile-ud-value">{currentViewTicket.comment || "-"} </span>
              </div>
            </div>
            <div className="profile-ud-item">
              <div className="profile-ud wider">
                <span className="data-label">Resolved Time (hrs)</span>
                <span className="data-value">
                  {currentViewTicket.resolutionTime ? convertToHoursAndMinutes(currentViewTicket.resolutionTime) : "-"}
                </span>
              </div>
            </div>
            {currentViewTicket.category === "preventative" &&
              (
                <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Month</span>
                    <span className="profile-ud-value">{currentViewTicket.months ? MONTH[currentViewTicket.months] : "-"} </span>
                  </div>
                </div>

              )
            }
            {currentViewTicket.category === "preventative" &&
              (
                <div className="profile-ud-item">
                  <div className="profile-ud wider">
                    <span className="profile-ud-label">Year</span>
                    <span className="profile-ud-value">{currentViewTicket.year || "-"} </span>
                  </div>
                </div>

              )
            }
          </div>

          <div className="nk-data data-list">
            {
              <Col lg="12 pt-2">
                <FormGroup className="form-group">
                  <div className="form-control-wrap">
                    <Attachments
                      module={TASK}
                      id={currentViewTicket.id}
                      attachments={currentViewTicket.attachment}
                      subModule={"view"}
                    />
                  </div>
                </FormGroup>
              </Col>
            }

          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              CreatedAt: {format(new Date(currentViewTicket?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              UpdatedAt: {format(new Date(currentViewTicket?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
        </Block>
      </Block>
    </>
  );
};
export default ViewTicket;
