import { format } from "date-fns";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  Icon,
  PaginationComponent,
  Sidebar,
  UserAvatar,
} from "../../../components/Component";
import FilteredByList from "../../../components/FilterBadge";
import { MONTH_OPTIONS, selectedMaintenanceTab, ticketViewType } from "../../../constants/common";
import Head from "../../../layout/head/Head";
import ModalViewer from "../../plant_management/ModalViewer";
import { TicketManagementContext } from "../TicketManagementProvider";
import TicketViewContainer from "../containers/TicketViewContainer";
import ExportForm from "./ExportForm";
import TicketListFilter from "./TicketsListFilter";
import { MONTH, statusColorTheme } from "../strings/components/constants";
import { createColumnHelper } from "@tanstack/react-table";
import { findUpper, openImageInNewTab } from "../../../utils/Utils";
import { updateViewSettings } from "../../../repositories/ViewRepository";
import TanstackTable from "../../../components/tableV1/Table";
import SortableColumns from "../../../components/tableV1/SortableColumns";
import { taskCategory } from "../../../constants/common";
import useBoolean from "../../../hooks/useBoolean";
import { CORRECTIVE_TASK_FIELDS, REMEDIAL_TASK_FIELDS, PREVENTATIVE_TASK_FIELDS } from "./TicketFields";
import ToolTip from "../../../components/ToolTip";
import AttachmentList from "../../../components/FileAttachment";
import { convertToHoursAndMinutes } from "../../../utils/DateUtil";
const columnHelper = createColumnHelper();

const TicketListCompact = ({ ticketsList, viewingPage }) => {
  // REFS
  const focusSearchRef = React.useRef();

  const location = useLocation().search;
  const status = new URLSearchParams(location).get("status");
  const dashboard = new URLSearchParams(location).get("dashboard");

  const TaskId = JSON.parse(new URLSearchParams(location).get("id"));

  const ticketManagementContext = useContext(TicketManagementContext);

  const isPreventativeMaintenance = viewingPage === "preventative";

  //ticketsList,
  const { pagination, ticketsLoading, filterOptions, loadTicketList } = ticketManagementContext;
  const { filterParams, handleApplyFilter, sfState, showFilter, isFilterApplied } = ticketManagementContext;
  const { tasksColumnSettings, userList, correctivetasks, setTasksColumnSettings } = ticketManagementContext
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ticket, setTicket] = useState("");
  const [view, setView] = useState("");
  const [isToggleTicket, setToggleTicket] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isColumnArrangeModalOpen, icamState] = useBoolean(false);
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [isAttachmentModalOpen, iamoState] = useBoolean(false);

  const viewing = (view) => {
    switch (view) {
      case "corrective": return CORRECTIVE_TASK_FIELDS
      case "remedial": return REMEDIAL_TASK_FIELDS
      case "preventative": return PREVENTATIVE_TASK_FIELDS
      default:
        return NaN;
    }
  }
  const renderTableValue = (tableValue) => {
    if (!tableValue) {
      return <div>-</div>;
    }
    return <div data-toggle="tooltip" style={{ cursor: "pointer" }} title={tableValue}>{tableValue} </div>;
  };

  const STATUSES = {
    OPEN: "Open",
    IN_PROGRESS: "In Progress",
    READY_FOR_APPROVAL: "Ready For Approval",
    COMPLETED: "Completed",
  };

  function tableColumn(columnSetting) {
    switch (columnSetting.field_key) {
      case "title":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => "Ticket Name",
          cell: (info) => {
            return (
              <div style={{ display: "flex", gap: "10px", cursor: "pointer", }}
                onClick={() => {
                  viewTicket(info.row.original.id);

                }}>
                <div className="user-card">
                  <UserAvatar theme={"primary"} className="xs" text={findUpper(info.row.original?.title)} />
                  <div className="user-name"></div>
                  <span
                    data-toggle="tooltip"
                    title={info.row.original?.title}
                    style={{
                      width: info.row.original?.title?.length > 50 ? "350px" : "",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      color: "#364a63"
                    }}
                    className="tb-lead"
                  >
                    {info.row.original?.title || "-"}
                  </span>
                </div>

              </div>
            );
          },
        });
      case "status":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.status) {
              return (
                <>
                  <Badge className="text-capitalize" style={statusColorTheme[info.row.original?.status]} pill>
                    <span>{STATUSES[info.row.original?.status] || "-"}</span>
                  </Badge>
                </>
              )

            }
            else return "-"
          }

        });
      case "identifier":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "startedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.startedAt) {
              return `${format(new Date(info.row.original?.startedAt || null), "dd/MM/yyyy")}`
            }
            else return "-"
          }
        });
      case "resolvedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.resolvedAt) {
              return `${format(new Date(info.row.original?.resolvedAt || null), "dd/MM/yyyy")}`
            }
            else return "-"
          }
        });
      case "resolutionTime":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.resolutionTime) {
              const formattedTime = convertToHoursAndMinutes(info.row.original.resolutionTime)
              return formattedTime
            }
            else return "-"
          }
        });
      case "plantId":
        return columnHelper.accessor("plants", {
          id: "plantId",
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.row.original.plants?.name),
        });
      case "slaId":
        return columnHelper.accessor("sla", {
          id: "slaId",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.row.original.sla?.name),
        });
      case "isArchived":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? "Archived" : "UnArchived"}</p>,
        });
      case "priority":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "labourHours":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "code":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "frequency":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "year":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "months":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.months?.length) {
              return <p>-</p>;
            }
            const monthList = MONTH_OPTIONS.filter((item) => info.row.original?.months.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {monthList.map((m, i) => {
                  return (
                    <div key={`month-${i}`}>
                      {m.label}
                      {i !== monthList.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "description":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <ToolTip id={`id-${info.row.original?.id}-description`} value={info.getValue()} />
        });
      case "comment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <ToolTip id={`id-${info.row.original?.id}-comment`} value={info.getValue()} />,
        });

      case "category":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.category) {
              return `${taskCategory(info.row.original.category)}`
            }
          }

        });
      case "assetCategoryId":
        return columnHelper.accessor("assetCategory", {
          enableResizing: true,
          id: "assetCategoryId",
          header: () => "Asset Category",
          cell: (info) => renderTableValue(info.row.original?.assetCategory?.name),
        });
      case "createdById":
        return columnHelper.accessor("createdBy", {
          id: "createdById",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.createdBy) {
              return (
                <p>
                  {info.row.original?.createdBy?.firstName}
                  {info.row.original?.createdBy?.lastName}
                </p>
              );
            }
            return "-";
          },
        });
      case "parentId":
        return columnHelper.accessor("parentId", {
          id: "parentId",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.parentId) {
              const parentId = info.row.original.parentId
              if (correctivetasks.length) {
                const parentTask = correctivetasks.find((task) => task.value === parentId)
                return parentTask.label
              }
              else return "-"
            }
            return "-";
          },
        });
      case "fieldEngineerIds":
        return columnHelper.accessor("fieldEngineerIds", {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.fieldEngineerIds?.length) {
              return <p>-</p>;
            }
            const user = userList.filter((item) => info.row.original?.fieldEngineerIds.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {user.map((u, i) => {
                  return (
                    <div key={`field-engineer-${i}`}>
                      {u.label}
                      {i !== user.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "assignedToIds":
        return columnHelper.accessor("assignedToIds", {
          id: columnSetting.field_key,
          enableSorting: false,
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.assignedToIds?.length) {
              return <p>-</p>;
            }
            const user = userList.filter((item) => info.row.original?.assignedToIds.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {user.map((u, i) => {
                  return (
                    <div key={`field-engineer-${i}`}>
                      {u.label}
                      {i !== user.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "attachment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <AttachmentList
            attachments={info.row.original?.attachment}
            taskName={info.row.original?.title}
            openImageInNewTab={openImageInNewTab}
            setSelectedAttachment={setSelectedAttachment}
            iamoState={iamoState}
          />
        });
      default:
        return columnHelper.accessor(columnSetting.field_key, {
          id: "id",
          enableSorting: false,
          header: () => columnSetting.field_key ?? "",
          cell: (info) => {
            return <p>unknown column</p>;
          },
        });
    }
  }

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // Changing state value when searching name
  useEffect(() => { }, [onSearchText]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleExportModal = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  async function handleUpdateViewSettings(payload) {
    return await updateViewSettings(payload);
  }

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
    const updatedSettings = {
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters, sortingField: field, sortingOrder: sortOrder },
    };
    handleUpdateViewSettings(updatedSettings);
    loadTicketList(updatedSettings, viewingPage);
    setTasksColumnSettings(updatedSettings);
  };
  const VIEW = {
    VIEW: "VIEW",
  };
  const viewTicket = (ticketId) => {
    setTicket(ticketId);
    setView(VIEW.VIEW);
    toggleModal();
  };
  useMemo(() => {
    if (TaskId) {
      viewTicket(TaskId);
    }
  }, []);

  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return (
        <TicketViewContainer
          isPreventativeMaintenance={isPreventativeMaintenance}
          ticketId={ticket}
          setIsOpen={setIsOpen}
        />
      );
    }
  };
  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText: onSearchText });
    loadTicketList({
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters, taskText: onSearchText },
    }, viewingPage);
  };

  const onSuccessfulModal = () => {
    toggleExportModal();
  };

  const category = (categoryName) => {
    switch (categoryName) {
      case "Corrective Maintenance Tickets":
        return "corrective";
      case "Preventative Maintenance Tickets":
        return "preventative";
      case "Remedial Maintenance Tickets":
        return "remedial";
      default:
        return "N/A";
    }
  };

  const taskCurrentStatus = filterOptions?.taskStatuses?.map((item) => {
    return item?.value;
  });
  const getExportComponent = () => {
    return (
      <ExportForm
        isPreventativeMaintenance={isPreventativeMaintenance}
        onSuccessfulModal={onSuccessfulModal}
        filterParams={filterParams}
        taskCurrentStatus={taskCurrentStatus}
        setIsExportModalOpen={setIsExportModalOpen}
        category={category(tabName)}
      />
    );
  };
  const createExport = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  let engineers = [];
  const currentItems = ticketsList?.items || [];

  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }
  const assignedEngineerData = React.useMemo(() => {
    if (filterParams && filterParams.selectedAssignedEngineer.length) {
      const engineersData = filterParams.selectedAssignedEngineer.map((item) => {
        return filterOptions.assignedEngineer?.[
          filterOptions.assignedEngineer?.findIndex((status) => status.value === item)
        ];
      });
      return engineersData
    }
  }, [filterParams, ticketsList])

  // const assignedEngineerSelectedData = filterParams.selectedAssignedEngineer && filterParams.selectedAssignedEngineer.map((item, key) => {
  //   return filterOptions.assignedEngineer?.[
  //     filterOptions.assignedEngineer?.findIndex((status) => status.value === item)
  //   ];
  // });
  const plantSelectedData = filterParams.selectedPlant && filterParams.selectedPlant.map((item, key) => {
    return filterOptions.plants?.[filterOptions.plants?.findIndex((status) => status.value === item)];
  });

  const { pathname } = useLocation();

  const selectedTab = pathname.split("/");

  const tabName = selectedMaintenanceTab[selectedTab[selectedTab.length - 1]];

  const columns = React.useMemo(() => {
    if (tasksColumnSettings?.fields?.length) {
      return tasksColumnSettings.fields
        .filter((cs) => cs.isChecked)
        .map((columnSetting) => {
          return tableColumn(columnSetting);
        });
    }

    return [];
  }, [ticketsList.items]);

  const handleClearSearch = async () => {
    setSearchText("");
    handleApplyFilter({ ...filterParams, searchText: "" });
    loadTicketList({
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters, taskText: "" },
    }, viewingPage);
    toggle();
  }

  const renderTable = React.useMemo(() => {
    return (
      <TanstackTable
        dataSource={ticketsList.items ?? []}
        _column={columns}
        columnSettings={tasksColumnSettings}
        onColumWidthChanged={(resizedColumn) => {
          const _tasksColumnSettings = [...tasksColumnSettings.fields].map((ts) => {
            if (ts.field_key === resizedColumn.field_key) {
              return {
                ...ts,
                width: resizedColumn.width,
              };
            } else {
              return ts;
            }
          });
          handleUpdateViewSettings({
            entityType: ticketViewType(viewingPage),
            fields: _tasksColumnSettings,
          });
          setTasksColumnSettings((prev) => ({ ...prev, fields: _tasksColumnSettings }));
        }}
        handleSorting={(sortingParams) => {
          handleNameSort(sortingParams.sortingOrder, sortingParams.sortingField, sortingParams.table);
        }}
      />
    );
  }, [columns, ticketsList.items, tasksColumnSettings]);

  const currentMaintenanceName = tabName.replace(/Tickets/g, "").trim()

  return (
    <React.Fragment>
      <Head
        title={
          tabName === "Corrective Maintenance Tickets"
            ? "Brighter-App | Corrective Maintenance"
            : tabName === "Remedial Maintenance Tickets"
              ? "Brighter-App | Remedial Maintenance"
              : "Brighter-App |  Preventative Maintenance "
        }
      />

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle className={"mt-5"} tag={"h3"}>
              {tabName}
            </BlockTitle>
            <BlockDes className="text-soft">
              <p>You have a total of {ticketsList?.pagination?.totalCount} Tickets.</p>
            </BlockDes>
            <div className="d-flex flex-wrap">
              {/* <FilteredByList data={filterParams.selectedTaskStatus} title={"Status"} isMap={false} isStr={false} /> */}
              {/* {assignedEngineerData && (
                <FilteredByList
                  data={assignedEngineerData}
                  title={"Assigned Engineer"}
                  isMap={true}
                  isStr={false}
                />
              )} */}
              {/* <FilteredByList data={plantSelectedData} title={"Plant"} isMap={true} isStr={false} /> */}
              {/* <FilteredByList data={filterParams.searchText} title={"Search By"} isNotMap={false} isStr={true} /> */}
              <FilteredByList data={filterParams.startDate} title={"Start Date"} isMap={false} isStr={true} />
              <FilteredByList data={filterParams.resolvedDate} title={"Resolved Date"} isMap={false} isStr={true} />
            </div>
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="menu-alt-r"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li>
                    <Button
                      className={`btn btn-white btn-outline-light ${currentItems.length > 0 ? "" : "disabled"}`}
                      type="export"
                      isLoading={exportLoading}
                      onClick={createExport}
                    >
                      <Icon name="download-cloud"> </Icon>
                      <span>Export</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      id="search-task-btn"
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div>
                    <Sidebar toggleState={showFilter}>
                      <div>
                        <TicketListFilter filterOptions={filterOptions} isPlantName={false} viewingPage={viewingPage} />
                      </div>
                    </Sidebar>
                    <div tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                    {ticketsList?.pagination?.totalCount > 0 &&
                      <div id="task-filter" tag="a" className="btn btn-icon " onClick={() => icamState.on()}>
                        <Icon name="view-list-fill"></Icon>
                      </div>}
                    <ModalViewer
                      title={`${selectedAttachment.taskName}'s Attachments`}
                      isOpen={isAttachmentModalOpen}
                      size="md"
                      toggleModal={iamoState.toggle}
                      component={
                        Object.keys(selectedAttachment).length ? (
                          <div>
                            {selectedAttachment?.attachments.map((attachment) => {
                              return (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <em class="icon ni ni-dot"></em>
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => openImageInNewTab(attachment?.files.key)}
                                  >
                                    <div style={{ color: "#0000EE", cursor: "pointer" }} href="">{attachment?.files?.name}</div>
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )
                      }
                    />
                    <ModalViewer
                      title="Corrective Task Columns"
                      isOpen={isColumnArrangeModalOpen}
                      size="md"
                      toggleModal={icamState.toggle}
                      component={
                        <SortableColumns
                          entityType={ticketViewType(viewingPage)}
                          fields={viewing(viewingPage)}
                          updateViewSettings={async (modifiedSettings) => {
                            const updatedSettings = await handleUpdateViewSettings({
                              ...tasksColumnSettings,
                              fields: modifiedSettings,
                            });
                            setTasksColumnSettings((prev) => ({ ...prev, fields: updatedSettings.fields }));
                            await loadTicketList(updatedSettings, viewingPage);
                          }}
                          closeModal={icamState.off}
                          visibleColumns={tasksColumnSettings.fields}
                        />
                      }
                    />
                  </div>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={handleClearSearch}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    id="search-input-task"
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by Ticket Name, status and Plant Name.enter to search"
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      if (e.keyCode === 27) {
                        setSearchText("");
                        handleApplyFilter({ ...filterParams, searchText: "" });
                        toggle();
                      }
                      if (e.keyCode === 88) {
                        setSearchText("");
                      }
                    }}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {ticketsLoading || ticketManagementContext.isLoadingFilters ? <Skeleton count={10} className="w-100" /> :
            (ticketsList?.pagination?.totalCount > 0) && renderTable}

          <div className="card-inner">
            {ticketsList.pagination && ticketsList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={pagination.size}
                totalItems={ticketsList.pagination.totalCount}
                paginate={(page) => {
                  ticketManagementContext.loadTicketList({
                    ...tasksColumnSettings,
                    filters: { ...tasksColumnSettings.filters, page },
                  }, viewingPage);
                  let updatedSetting
                  if (dashboard === 'yes') {
                    updatedSetting = {
                      ...tasksColumnSettings,
                      filters: {
                        ...tasksColumnSettings.filters, taskStatus: [status], page,
                        size: pagination.size, dashboard: "yes"
                      }
                    };
                  }
                  else {
                    updatedSetting = {
                      ...tasksColumnSettings,
                      filters: { ...tasksColumnSettings.filters, page }

                    }
                  }
                  ticketManagementContext.loadTicketList(updatedSetting, viewingPage, { status });
                  ticketManagementContext.updateShowListPage(page);
                }}
                currentPage={ticketsList.pagination.currentPage}
                paginatePage={(size) => {
                  ticketManagementContext.updateShowListSize(size);
                  let updatedSetting
                  if (dashboard === 'yes') {
                    updatedSetting = {
                      ...tasksColumnSettings,
                      filters: { ...tasksColumnSettings.filters, taskStatus: [status], size, dashboard: "yes" }
                    };
                  }
                  else {
                    updatedSetting = {
                      ...tasksColumnSettings,
                      filters: { ...tasksColumnSettings.filters, size },
                    };
                    handleUpdateViewSettings(updatedSetting);
                    setTasksColumnSettings(updatedSetting);
                  }
                  loadTicketList(updatedSetting, viewingPage, { status });
                }}
              />
            ) : (
              (!ticketsLoading && ticketsList?.pagination?.totalCount === 0) && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            )}
          </div>
        </DataTable>
      </Block>
      <ModalViewer title={tabName} isOpen={isOpen} size="xl" toggleModal={toggleModal} component={getComponent()} />
      <ModalViewer
        title={`${currentMaintenanceName} - Select the Export Fields`}
        isOpen={isExportModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default TicketListCompact;
